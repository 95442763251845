var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.permSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.permSnackbar),callback:function ($$v) {_vm.permSnackbar=$$v},expression:"permSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',[_vm._v("Le mie macchine virtuali ("+_vm._s(_vm.vms.length ? _vm.vms.length:'0')+")")])],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.vms,"item-key":"Name","expanded":_vm.expanded,"show-expand":"","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Apparati per pagina',
          itemsPerPageAllText: 'Tutti gli apparati',
          itemsPerPageOptions: [10, 25, 50, -1],
          pageText: '',
        }},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'VM per pagina'","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna Macchina Virtuale ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna Macchina Virtuale ")])]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" FQDN: "+_vm._s(item.FQDN ? item.FQDN: 'N/A')+" "),_c('br'),_vm._v(" OS: "+_vm._s(item.OS ? item.OS: (item.OS2 ? item.OS2: 'N/A'))+" "),_c('br'),_vm._v(" Core: "+_vm._s(item.Cores)+" - Sockets: "+_vm._s(item.Sockets)),_c('br'),_vm._v(" Memoria Garantita: "+_vm._s((item.MemGuaranteed/1073741824).toFixed(2))+" Gb"),_c('br'),_vm._v(" Memoria Massima: "+_vm._s((item.MemMax/1073741824).toFixed(2))+" Gb"),_c('br')])]}},{key:"item.Status",fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.Status == 'up' ? 'success' : 'error',"dark":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.Status == 'up' ? 'mdi-check' : 'mdi-close'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Status == 'up' ? 'Accesa' : 'Spenta'))])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }