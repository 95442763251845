<template>
  <v-container>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card elevation="12" min-height="600">
      <v-row>
        <v-col cols="6">
          <v-card-title>Le mie macchine virtuali ({{ vms.length ? vms.length:'0'}})</v-card-title>
        </v-col>
        
      </v-row>

      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items=vms
          item-key="Name"
          :expanded.sync="expanded"
          show-expand
          @click:row="clickRow"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Apparati per pagina',
            itemsPerPageAllText: 'Tutti gli apparati',
            itemsPerPageOptions: [10, 25, 50, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              
              itemsPerPageText="'VM per pagina'"
              clearable
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessuna Macchina Virtuale </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessuna Macchina Virtuale </v-sheet>
          </template>

        <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          FQDN: {{ item.FQDN ? item.FQDN: 'N/A' }} <br>
          OS: {{ item.OS ? item.OS: (item.OS2 ? item.OS2: 'N/A')}} <br>
          Core: {{ item.Cores }} - Sockets: {{ item.Sockets }}<br>
          Memoria Garantita: {{ (item.MemGuaranteed/1073741824).toFixed(2)  }} Gb<br>
          Memoria Massima: {{ (item.MemMax/1073741824).toFixed(2)  }} Gb<br>
        </td>
      </template>
      <template v-slot:item.Status="{ item }">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="item.Status == 'up' ? 'success' : 'error'"
              dark
              small
              v-bind="attrs"
                  v-on="on"
            >
            <v-icon>{{ item.Status == 'up' ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-chip>

        </template>
        <span>{{ item.Status == 'up' ? 'Accesa' : 'Spenta' }}</span>
          </v-tooltip>
          </template>
         
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "MyVM",
  data: () => {
    return {

      vms: [],
      search: "",
      expanded: [],
      loading: false,
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
     
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome VM",
          align: "start",
          sortable: true,
          value: "Name",
         },
        {
          text: "Referenti",
          align: "start",
          sortable: false,
          value: "Owners",
        },
        {
          text: "Stato",
          align: "center",
          sortable: true,
          value: "Status",
        },
      ];
    },
    // ...mapState(["userInitials", "userRole", "userPrivacy"]),
  },

  created: function () {
    this.loading = true;
    this.loadMyVM();
  },
  methods: {
    loadMyVM() {
      this.loading = true;
      

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/vms/" + this.$store.state.userMatricola + '/mylist', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.vms = response.data.map(item => ({
            Name: item.name,
            Owners: item.comment,
            OS:  item.guest_operating_system.family,
            OS2: item.os.type,
            FQDN: item.fqdn,
            MemGuaranteed: item.memory_policy.guaranteed,
            MemMax: item.memory,  
            Cores: item.cpu.topology.cores,
            Sockets: item.cpu.topology.sockets,
            Status: item.status,
          }));

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorText = err.response;
          this.permSnackbar = true;
          this.snackbarColor = "red";
          this.loading = false;
        });
    },

    // expand the row data in the table
    clickRow(item, event) {
      if(event.isExpanded) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item);
      }
    }

  },
};
</script>